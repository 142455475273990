import { Standard } from '@getvim/vim-connect';
import { getWritebackAssessmentPayload } from './writebackAssessment';

const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function getRandomString(length: number = 5): string {
  let result: string = '';
  for (let i = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

function createFieldTemplate(fieldName: string, identifier: string | undefined): string {
  return identifier ? `$$$ ${fieldName} $$$ ${identifier}` : `$$$$$$$$ ${fieldName} $$$$$$$$`;
}

export function getWritebackAllEncounterFieldsPayload(
  key: string,
): Standard.WriteBacks.UpdateEncounter {
  const identifier: string | undefined = key === 'specific-payload' ? getRandomString() : undefined;
  return {
    subjective: {
      generalNotes: createFieldTemplate('subjective FREE TEXT', identifier),
      reviewOfSystemsNotes: createFieldTemplate('ROS FREE TEXT', identifier),
      historyOfPresentIllnessNotes: createFieldTemplate('HPI FREE TEXT', identifier),
      chiefComplaintNotes: createFieldTemplate('chiefComplaintNotes', identifier),
    },
    objective: {
      generalNotes: createFieldTemplate('objective FREE TEXT', identifier),
      physicalExamNotes: createFieldTemplate('PE FREE TEXT', identifier),
    },
    assessments: {
      generalNotes: createFieldTemplate('assessments generalNotes', identifier),
      diagnosisCodes: getWritebackAssessmentPayload('R14.0').assessments?.diagnosisCodes,
    },
    plans: {
      generalNotes: createFieldTemplate('plans', identifier),
      procedureCodes: [{ code: '3210F' }],
    },
    patientInstructions: { generalNotes: createFieldTemplate('patientInstructions', identifier) },
  };
}
