import React, {
  RefObject,
  SyntheticEvent,
  ChangeEvent,
  MouseEvent,
  createRef,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Box, Container } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  DisplayMode,
  Response,
  SendDataToWidgetType,
  OSSettings,
  OSLog,
  OSReports,
  OSSimulatorData,
  OSScreenPosition,
  OSAction,
  OSWriteback,
  OSStateData,
} from '@getvim/vim-connect-app';
import { Common } from '@getvim/vim-connect';
import { registerWidget } from '@getvim/utils-vim-connect-communication';
import {
  getUpdatedLogs,
  sendTabIdData,
  sendWidgetExpandedData,
  sendRunAuthenticatedMonitorsData,
  sendOrganizationConfig,
  sendLogsData,
  sendScreenPositionData,
} from '../../../services';
import { SEARCH_TYPE, PointerEventsState, TabType, RunMonitors } from '../../../types';
import {
  Actions,
  Automations,
  Connectors,
  Events,
  HeaderWarning,
  Header,
  HiddenData,
  Logs,
  Reports,
  Settings,
  States,
  TabPanel,
  TabsMenu,
} from '../';
import '../../../assets/css/App.less';

const isDevelopment: boolean = Common.isDevelopmentMode(new URL(window.location.href)?.origin);
const refs: Map<any, any> = new Map();
let isUpdatingScreenPosition: boolean = false;
let latestPosition: OSScreenPosition | null = null;

export const Main = () => {
  let tabIndex: number = -1;
  const eventsDivRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  const containerRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  const positionRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [offsetX, setOffsetX] = useState<number>(0);
  const [offsetY, setOffsetY] = useState<number>(0);
  const [style, setStyle] = useState({ transform: `translate(0px, 0px)` });
  const [osSimulatorData, setOSSimulatorData] = useState<OSSimulatorData>();
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.DARK);
  const [tabId, setTabId] = useState<number>(0);
  const [widgetExpanded, setWidgetExpanded] = useState<boolean>(false);
  const [widgetMoved, setWidgetMoved] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [states, setStates] = useState<OSStateData[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<any>(events);
  const [filterText, setFilterText] = useState<string>('');
  const [searchType, setSearchType] = useState<string>(SEARCH_TYPE.NAME.key);
  const [actionResponse, setActionResponse] = useState<Response>();
  const [pointerEventsState, setPointerEventState] = useState<PointerEventsState>(
    PointerEventsState.NONE,
  );
  const [windowHandlesTrack, setWindowHandlesTrack] = useState<any[]>([]);
  const [reportNames, setReportNames] = useState<string[]>([]);
  const [reports, setReports] = useState<OSReports>();
  const [isAuthMonitorRunning, setIsAuthMonitorRunning] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList.add('os-simulator-widget');
    registerWidget((payload: any) => {
      switch (payload.type) {
        case SendDataToWidgetType.OS_SIMULATOR_DATA: {
          setOSSimulatorData(payload.data);
          if (!payload.data.capabilities) {
            setTabId(payload.data.osGeneral?.tabId ?? 0);
            setWidgetExpanded(payload.data.osGeneral?.widgetExpanded ?? false);
            setDisplayMode(payload.data.osGeneral?.displayMode ?? DisplayMode.DARK);
            if (payload.data.osGeneral?.screenPosition) {
              setStyle({
                transform: `translate(${payload.data.osGeneral.screenPosition.x}px, ${payload.data.osGeneral.screenPosition.y}px)`,
              });
              setWidgetMoved(true);
            }
          }
          break;
        }
        case SendDataToWidgetType.EVENT: {
          payload.data.receivedDate = new Date();
          refs.set(payload.data.id, createRef());
          setEvents((prevState) => [...prevState, payload.data]);
          break;
        }
        case SendDataToWidgetType.STATE: {
          const newState: OSStateData = payload.data;
          setStates((prevStates) => {
            const updatedStates: OSStateData[] = [newState, ...prevStates];
            updatedStates.sort(
              (a: OSStateData, b: OSStateData) =>
                a.receivedDate.getTime() - b.receivedDate.getTime(),
            );
            return updatedStates;
          });
          break;
        }
        case SendDataToWidgetType.ACTION_RESPONSE: {
          setActionResponse(payload.data);
          break;
        }
        case SendDataToWidgetType.WRITEBACK_RESPONSE: {
          refs
            .get(payload.data.id)
            ?.current.operationResponse(payload.data.writebackId, payload.data.status);
          break;
        }
        case SendDataToWidgetType.WRITEBACK_RESPONSE_V2: {
          refs
            .get(payload.data.id)
            ?.current.operationResponseV2(payload.data.writebackId, payload.data.writebackResponse);
          break;
        }
        case SendDataToWidgetType.UI_INJECTION_RESPONSE: {
          refs
            .get(payload.data.id)
            ?.current.operationResponse(payload.data.uiInjectionId, payload.data.status);
          break;
        }
        case SendDataToWidgetType.WINDOW_HANDLE_TRACK: {
          payload.data.receivedDate = new Date();
          setWindowHandlesTrack((prevState) => [...prevState, payload.data]);
          break;
        }
        case SendDataToWidgetType.REPORT_NAMES: {
          setReportNames(payload.data);
          break;
        }
        case SendDataToWidgetType.REPORT: {
          setReports(payload.data);
          break;
        }
      }
    });

    function onmousemove(clientX: number, clientY: number): void {
      const element: Element | null = document.elementFromPoint(clientX, clientY);
      const newState: PointerEventsState =
        !element ||
        ['BODY', 'HTML'].includes(element.tagName) ||
        ['theme-css-variables-wrapper', 'root'].includes(element.id)
          ? PointerEventsState.NONE
          : PointerEventsState.INITIAL;
      setPointerEventState(newState);
    }

    window.addEventListener('message', (e: globalThis.MessageEvent): void => {
      if (e.data.type === 'host-mousemove') {
        const { clientX, clientY } = e.data.payload;
        onmousemove(clientX, clientY);
      }
    });

    document.body.addEventListener('mousemove', (e: globalThis.MouseEvent): void => {
      const { clientX, clientY } = e;
      onmousemove(clientX, clientY);
    });

    function handleEsc(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        setStyle({ transform: 'translate(0px, 0px)' });
        setWidgetMoved(false);
        sendScreenPositionData(null);
      }
    }

    window.addEventListener('keydown', handleEsc);

    return (): void => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect((): void => {
    (window as any).$extensionTransparentPort?.postMessage({
      type: 'change-pointer-events',
      payload: { state: pointerEventsState },
    });
  }, [pointerEventsState]);

  useEffect((): void => {
    setFilteredEvents(filterText ? SEARCH_TYPE[searchType].func(events, filterText) : events);
  }, [events, searchType, filterText]);

  const handleDragStart = (e: MouseEvent<HTMLDivElement>): void => {
    const mouseX: number = e.clientX;
    const mouseY: number = e.clientY;
    const rect: DOMRect | undefined = positionRef.current?.getBoundingClientRect();
    if (rect) {
      setOffsetX(mouseX - rect.left);
      setOffsetY(mouseY - rect.top);
    }
  };

  const handleDrag = (e: MouseEvent<HTMLDivElement>): void => {
    const mouseX: number = e.clientX;
    const mouseY: number = e.clientY;
    if (mouseX > 0 && mouseY > 0) {
      const screenPositionX: number = mouseX - offsetX - 585;
      const screenPositionY: number = mouseY - offsetY;
      setStyle({ transform: `translate(${screenPositionX}px, ${screenPositionY}px)` });
      setWidgetMoved(true);
      latestPosition = { x: screenPositionX, y: screenPositionY };
      if (!isUpdatingScreenPosition) {
        isUpdatingScreenPosition = true;
        setTimeout(() => {
          isUpdatingScreenPosition = false;
          sendScreenPositionData(latestPosition);
        }, 1000);
      }
    }
  };

  const handleTabChange = (_e: SyntheticEvent, newTabId: number): void => {
    setTabId(newTabId);
    sendTabIdData(newTabId);
  };

  const handleClearEventsClick = (): void => {
    if (filteredEvents.length > 0) {
      setEvents([]);
    }
  };

  const handleClearStatesClick = (): void => {
    if (states.length > 0) {
      setStates([]);
    }
  };

  const handleClearAllHighlighterEvents = (): void => {
    setWindowHandlesTrack([]);
  };

  const handleCleanTextClick = (): void => {
    if (filterText.length) {
      setFilterText('');
    }
  };

  const handleFilterTextChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFilterText(e.target.value);
  };

  const handleDeleteEventClick = (e: MouseEvent<HTMLButtonElement>): void => {
    const id: string = e.currentTarget.id.split('_')[0];
    setEvents((prevStat: any) => prevStat.filter((e: any) => e.id !== id));
    setFilteredEvents((prevStat: any) => prevStat.filter((e: any) => e.id !== id));
  };

  const handleFiltersEventsClick = (e: MouseEvent<HTMLButtonElement>): void => {
    setFilterText(e.currentTarget.id.split('_')[2]);
  };

  const handleSearchTypeChange = (e: SelectChangeEvent): void => {
    setSearchType(e.target.value);
  };

  const handleOrganizationConfigChange = (settings: OSSettings): void => {
    setOSSimulatorData({ ...osSimulatorData!, osSettings: settings });
  };

  const handleWritebackDataChange = (writeback: OSWriteback): void => {
    const osWritebacks: Record<string, OSWriteback> = osSimulatorData?.osWritebacks ?? {};
    osWritebacks[writeback.operationId] = writeback;
    setOSSimulatorData({ ...osSimulatorData!, osWritebacks });
  };

  const handleActionDataChange = (action: OSAction): void => {
    setOSSimulatorData({ ...osSimulatorData!, osAction: action });
  };

  const handleLogsUpdate = (logs: OSLog[]): void => {
    const updatedLogs: OSLog[] = getUpdatedLogs(logs, osSimulatorData);
    setOSSimulatorData({ ...osSimulatorData!, osLogs: { logs: updatedLogs } });
    sendLogsData(updatedLogs);
  };

  const handleDisplayModeChange = (displayMode: DisplayMode): void => {
    setDisplayMode(displayMode);
  };

  const handleWidgetExpanded = (): void => {
    setWidgetExpanded(!widgetExpanded);
    sendWidgetExpandedData(!widgetExpanded);
  };

  const handleClearAllLogs = (): void => {
    setOSSimulatorData({ ...osSimulatorData!, osLogs: { logs: [] } });
    sendLogsData([]);
  };

  const handleRunMonitorsClick = ({
    value,
    setIsAuth,
    sendData,
    setBoth,
    startupAuthMonitors = undefined,
  }: RunMonitors): void => {
    if (setIsAuth) {
      setIsAuthMonitorRunning(value);
      if (startupAuthMonitors) {
        setOSSimulatorData({
          ...osSimulatorData!,
          osGeneral: {
            ...osSimulatorData!.osGeneral,
            startupAuthMonitors: startupAuthMonitors.value,
          },
        });
      }
    }
    if (sendData && value) {
      setBoth ? sendOrganizationConfig() : sendRunAuthenticatedMonitorsData();
    }
  };

  const handleSetStateConfidentFilterClick = (isStateConfidentFilter: boolean): void => {
    setOSSimulatorData({
      ...osSimulatorData!,
      osGeneral: {
        ...osSimulatorData!.osGeneral,
        isStateConfidentFilter,
      },
    });
  };

  const handleScrollTopEventClick = (): void => {
    if (eventsDivRef.current) {
      eventsDivRef.current.scrollIntoView();
    }
  };

  const handleNavigateToTab = (tabName: TabType): void => {
    const tabPanel: Element | null = document.querySelector(`.tab-panel[data-name="${tabName}"]`);
    const tabIndex: number | null = tabPanel
      ? Number.parseInt(tabPanel.id.match(/\d+/)?.[0] || '0', 10)
      : null;
    console.log(`Navigate to tab ${tabIndex} | ${tabName}`);
    handleTabChange(Object.create(null), tabIndex!);
  };

  const isRunAuthMonitors: boolean =
    isAuthMonitorRunning || osSimulatorData?.osGeneral?.startupAuthMonitors || false;
  const localLogs: OSLog[] = osSimulatorData?.osLogs?.logs ?? [];

  return (
    <Container
      className={`os-simulator ${widgetExpanded ? 'expanded' : 'minimized'}${
        widgetMoved ? ' moved' : ''
      } ${displayMode}`}
      style={style}
      ref={containerRef}
    >
      <HiddenData
        osSimulatorData={osSimulatorData}
        events={events}
        filteredEvents={filteredEvents}
        filterText={filterText}
        onNavigateToTab={handleNavigateToTab}
      />
      <Header
        ref={positionRef}
        isDevelopmentMode={isDevelopment}
        adapterInfo={osSimulatorData?.osAdapterInfo}
        eventsCount={filteredEvents.length}
        widgetExpanded={widgetExpanded}
        onChangeWidgetExpanded={handleWidgetExpanded}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
      />
      <TabsMenu
        tabId={tabId}
        widgetExpanded={widgetExpanded}
        isAuthMonitorRunning={isRunAuthMonitors}
        onTabChange={handleTabChange}
      />
      <HeaderWarning
        isAuthMonitorRunning={isRunAuthMonitors}
        adapterName={osSimulatorData?.osAdapterInfo?.adapterName}
        settings={osSimulatorData?.osSettings}
        onNavigateToTab={handleNavigateToTab}
        onRunMonitorsClick={handleRunMonitorsClick}
      />
      <div
        className={`os-container${
          widgetExpanded ? (isRunAuthMonitors ? ' clear' : '') : ' hidden'
        }`}
      >
        <Box sx={{ width: '100%' }} ref={eventsDivRef}>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.EVENTS}>
            <Events
              refs={refs}
              events={events}
              writebacksData={osSimulatorData?.osWritebacks}
              filteredEvents={filteredEvents}
              filterText={filterText}
              searchType={searchType}
              localLogs={localLogs}
              onClearAllEvents={handleClearEventsClick}
              onClearFilterText={handleCleanTextClick}
              onFilterTextChange={handleFilterTextChange}
              onSearchTypeChange={handleSearchTypeChange}
              onFilterEventsClick={handleFiltersEventsClick}
              onDeleteEventClick={handleDeleteEventClick}
              onLogsUpdate={handleLogsUpdate}
              onScrollTopEventClick={handleScrollTopEventClick}
              onWritebackDataChange={handleWritebackDataChange}
            />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.STATE}>
            <States
              states={states}
              isStateConfidentFilter={osSimulatorData?.osGeneral?.isStateConfidentFilter}
              onClearAllStates={handleClearStatesClick}
              onSetStateConfidentFilterClick={handleSetStateConfidentFilterClick}
            />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.ACTIONS}>
            <Actions
              actionData={osSimulatorData?.osAction}
              actionResponse={actionResponse}
              localLogs={localLogs}
              onLogsUpdate={handleLogsUpdate}
              onActionDataChange={handleActionDataChange}
            />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.CONNECTORS}>
            <Connectors
              windowHandlesTrack={windowHandlesTrack}
              onClearAllHighlighterEvents={handleClearAllHighlighterEvents}
            />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.LOGS}>
            <Logs logs={osSimulatorData?.osLogs} onClearAllLogs={handleClearAllLogs} />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.REPORTS}>
            <Reports reportNames={reportNames} reports={reports} />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.AUTOMATIONS}>
            <Automations />
          </TabPanel>
          <TabPanel value={tabId} index={++tabIndex} name={TabType.SETTINGS}>
            <Settings
              settings={osSimulatorData?.osSettings}
              adapterInfo={osSimulatorData?.osAdapterInfo}
              displayMode={displayMode}
              capabilitiesLastUpdate={osSimulatorData?.capabilitiesLastUpdate}
              adapterKPILastUpdate={osSimulatorData?.adapterKPILastUpdate}
              startupAuthMonitors={osSimulatorData?.osGeneral?.startupAuthMonitors}
              onRunMonitorsClick={handleRunMonitorsClick}
              onDisplayModeChange={handleDisplayModeChange}
              onOrganizationConfigChange={handleOrganizationConfigChange}
            />
          </TabPanel>
        </Box>
      </div>
    </Container>
  );
};

Main.displayName = 'Main';
