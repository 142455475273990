import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Switch } from '@mui/material';
import { OSStateData } from '@getvim/vim-connect-app';
import { ICONS_MAP, StatesProps } from '../../../types';
import { addLeadingZero, getItemId, sendSetStateConfidentFilterData } from '../../../services';
import { State } from '../state';
import { ButtonIcon } from '../common';

export const States: FC<StatesProps> = ({
  states,
  isStateConfidentFilter = false,
  onClearAllStates,
  onSetStateConfidentFilterClick,
}) => {
  const [isConfidentFilterLocal, setIsStateConfidentFilterLocal] = useState<boolean>(false);

  useEffect((): void => {
    setIsStateConfidentFilterLocal(isStateConfidentFilter);
  }, [isStateConfidentFilter]);

  const handleStateConfidentFilterSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    onSetStateConfidentFilterClick(e.target.checked);
    setIsStateConfidentFilterLocal(e.target.checked);
    sendSetStateConfidentFilterData(e.target.checked);
  };

  const filteredStates: OSStateData[] = isConfidentFilterLocal
    ? states.filter((state: OSStateData) => state.data.confidence === 1)
    : states;
  const disabled: boolean = filteredStates.length === 0;

  return (
    <>
      <div className="states">
        <div className="controls-panel">
          <div className="clear-states">
            <ButtonIcon
              id="clear-all-event"
              className="button mini"
              onClick={onClearAllStates}
              disabled={disabled}
              icon={ICONS_MAP['delete']}
            />
          </div>
          <div className="confident-filter">
            <div className="label">Show confident 1 only</div>
            <div className="switcher">
              <Switch
                checked={isConfidentFilterLocal}
                onChange={handleStateConfidentFilterSwitch}
                color="warning"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-divider">States ({addLeadingZero(filteredStates.length)})</div>
      {filteredStates.map((state: OSStateData) => (
        <State key={getItemId(state.id, state.receivedDate)} state={state} />
      ))}
    </>
  );
};

States.displayName = 'States';
