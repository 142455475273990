import React, { FC } from 'react';
import ReactJson from 'react-json-view';
import { JsonViewerProps } from '../../../types';

export const JsonViewer: FC<JsonViewerProps> = ({ id, type, value, collapsed }) => {
  return (
    <>
      <div data-id={`${id}-hidden-json`} className={`raw-json-${type} ${id} hidden-element`}>
        {JSON.stringify(value)}
      </div>
      <ReactJson collapsed={collapsed} src={value ? value : undefined} />
    </>
  );
};

JsonViewer.displayName = 'JsonViewer';
