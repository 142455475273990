import axios from 'axios';
import { logger } from '@getvim/vim-connect-logger';
import { retry } from '@vim/promise';
import { WidgetsConfig } from './widgetBaseUrl';

const scopedLogger = logger.scope('getWidgetConfig');
const axiosInstance = axios.create({ timeout: 30000 });

export async function getWidgetsConfig(): Promise<WidgetsConfig> {
  try {
    let response: any;

    await retry(
      async (retryNumber: number) => {
        scopedLogger.info('Trying to get widgets config', {
          noPHI: true,
          retryNumber,
        });

        try {
          response = await axiosInstance.get<WidgetsConfig>('/api/widgetsConfig');
        } catch (error) {
          scopedLogger.warning('Failed to get widgets config', {
            error,
            noPHI: true,
          });
          throw error;
        }

        scopedLogger.info('get widgets config completed', { noPHI: true });
      },
      { retries: 5 },
    );

    return response.data;
  } catch (error) {
    scopedLogger.error(`Could not fetch client config!`, { error });
    throw error;
  }
}
