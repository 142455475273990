import { Logger } from '@getvim/vim-connect-logger';
import { Insurer } from '@getvim-os/standard';
import { Address } from '@getvim/platform-types';
import { ProviderSource } from '@getvim-core-apps/organizations';
import { isEmpty, mapKeys, omit, omitBy } from 'lodash-es';
import NodeCache from 'node-cache';
import { TransformedPatientInContextPayload } from '../../../../../standard/events';
import { PatientAddress } from '../../../../../standard/entities/patient';
import {
  ContentSupplierIdentifiersInput,
  ContentSupplierIdentifiersResult,
  getContentSupplierIdentifiers,
} from '../../../../../infra/common/api/patient/getContentSupplierIdsSources';

const CACHE_TTL = 15 * 60; // 15 minutes cache
const contentSupplierPatientIdentifiersCache = new NodeCache({ stdTTL: CACHE_TTL });

export const getContentSupplierPatientIdentifiers = async (
  patient: TransformedPatientInContextPayload,
  logger: Logger,
  insurerSources: Insurer[],
  providerSources: ProviderSource[],
): Promise<ContentSupplierIdentifiersResult[] | undefined> => {
  try {
    if (isEmpty(insurerSources) && isEmpty(providerSources)) {
      logger.info(
        'Received empty insurerSources && providerSources for getContentSupplierPatientIdentifiers',
      );
      return;
    }
    const { demographics, patientId, mrn, insurance, pcp } = patient ?? {};
    const { firstName, lastName, dateOfBirth, address } = demographics ?? {};
    const { memberId } = insurance ?? {};

    if (!firstName || !lastName || !dateOfBirth) {
      logger.info(
        'Received a patient with missing demographics fields in getContentSupplierPatientIdentifiers',
        {
          patientId,
          demographics,
        },
      );
      return;
    }

    const cachedContentSupplierPatientIdentifiers =
      contentSupplierPatientIdentifiersCache.get(patientId);

    logger.info('Current cached Content Supplier Patient Identifiers', {
      patientId,
      cachedContentSupplierPatientIdentifiers,
    });

    if (!cachedContentSupplierPatientIdentifiers) {
      const contentSupplierIdentifiersInput: ContentSupplierIdentifiersInput = {
        insurerSources,
        providerSources,
        firstName,
        lastName,
        dateOfBirth,
        patientId,
        mrn,
        memberId,
        address: transformClientAddressToBackend(address),
      };
      if (pcp && !isEmpty(pcp.npi)) {
        contentSupplierIdentifiersInput.metadata = { npi: pcp.npi };
      }

      const response = await getContentSupplierIdentifiers(contentSupplierIdentifiersInput);

      contentSupplierPatientIdentifiersCache.set(patientId, response);
    }

    return await contentSupplierPatientIdentifiersCache.get(patientId);
  } catch (error) {
    logger.error('Failed to get contentSupplierIdentifiers in patient payload transformer', {
      error,
      patient,
    });
  }
};

const transformClientAddressToBackend = (clientAddress?: PatientAddress) =>
  omitBy(mapClientAddressToBackend(clientAddress), (prop) => !prop);

const mapClientAddressToBackend = (
  clientAddress?: PatientAddress,
): Omit<Address, 'fullAddress'> | undefined => {
  if (isEmpty(clientAddress)) return;

  const normalizedClientAddress = {
    ...clientAddress,
    address1: clientAddress.address1?.toString(),
    address2: clientAddress.address2?.toString(),
    city: clientAddress?.city?.toString(),
  };

  const mapping = {
    zipCode: 'zip',
    address1: 'addressLine1',
    address2: 'addressLine2',
  };
  return mapKeys(omit(normalizedClientAddress, 'fullAddress'), (_, key) => mapping[key] ?? key);
};
