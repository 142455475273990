import { Standard } from '@getvim/vim-connect';
import { getWritebackAllEncounterFieldsPayload } from './writebackAllEncounterFields';

export function getWritebackFreeTextNotesPayload(key: string): Standard.WriteBacks.UpdateEncounter {
  const result: Standard.WriteBacks.UpdateEncounter = getWritebackAllEncounterFieldsPayload(key);
  delete result.addedDiagnoses;
  delete result.addedProcedures;
  delete result.assessments?.diagnosisCodes;
  delete result.plans?.procedureCodes;
  return result;
}
